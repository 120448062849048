import React from "react";
import PropTypes from "prop-types";

import "./_contacts-list.scss";

const ContactsList = ({ list }) => {
  return (
    <div className="contacts-list">
      <dl className="contacts-list__list">
        {list &&
          list.map(item => (
            <React.Fragment key={item.id}>
              <dt className="contacts-list__term">{item.term}</dt>
              <dd className="contacts-list__desc">
                <a
                  className="contacts-list__link"
                  href={item.href}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {item.desc}
                </a>
              </dd>
            </React.Fragment>
          ))}
      </dl>
    </div>
  );
};

ContactsList.propTypes = {
  list: PropTypes.array.isRequired,
  heading: PropTypes.string,
};

export default ContactsList;
