import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { navigation, kontaktai, kontaktaiArray } from "src/data";
import Header from "components/Header";
import About from "components/About";
import ContactsBlock from "components/ContactsBlock";
import Main from "components/Main";
import Footer from "components/Footer";

const Kontaktai = () => {
  const data = useStaticQuery(graphql`
    query KontaktaiImages {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Header
        items={navigation}
        logo={data.logo.childImageSharp.fluid}
        logoAlt="Kudrėnų sodybos Logotipas"
        navPosition="right"
      />
      <Main>
        <About
          heading={kontaktai.heading}
          firstParagraph={kontaktai.firstParagraph}
        />
        <ContactsBlock contacts={kontaktaiArray} />
      </Main>
      <Footer />
    </>
  );
};

export default Kontaktai;
