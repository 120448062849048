import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

import ContactsList from "components/ContactsList";
import Form from "components/Form";
import MapContainer from "components/MapContainer";
import { textTransition } from "components/Animations";

import "./_contacts-block.scss";

const ContactsBlock = ({ contacts }) => {
  const trigger = useRef(null);
  const form = useRef(null);
  const list = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tl.fromTo(form.current, duration, from, to, "1")
            .fromTo(list.current, duration, from, to, "-=0.7")
            .fromTo(map.current, duration, from, to);
          observer.unobserve(trigger.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (trigger.current) {
      observer.observe(trigger.current);
    }
  }, []);

  return (
    <div ref={trigger} className="contacts-block">
      <div className="contacts-block__wrapper">
        <div ref={list}>
          <ContactsList list={contacts} />
        </div>
        <div ref={map}>
          <MapContainer />
        </div>
      </div>
      <div ref={form}>
        <Form />
      </div>
    </div>
  );
};

export default ContactsBlock;
