import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./_form.scss";

const GETFORM_URL = "https://getform.io/f/035453f6-5f39-403a-81c5-e1fc9b1b06d8";

const Form = () => {
  const [submitted, setSubmitted] = useState(false);
  const { register, errors, handleSubmit, setError, reset } = useForm();

  const onSubmit = async data => {
    try {
      console.log("pries fetch");
      fetch(GETFORM_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      console.log("issiusta");
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! Įvyko serverio klaida :( ${error.message}`
      );
    }
  };

  const showSubmitError = msg => <p className="form__msg-error">{msg}</p>;

  const showThankYou = (
    <div className="form__msg-confirm-container">
      <p className="form__msg-confirm">
        Ačiū! Jūsų žinutė sėkmingai išsiųsta. Su Jumis susisieksime artimiausiu
        metu.
      </p>
      <button
        className="form__button"
        type="button"
        onClick={() => setSubmitted(false)}
      >
        Siųsti dar vieną?
      </button>
    </div>
  );

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post" className="form">
      <div className="form__row">
        <label htmlFor="name" className="form__label">
          Vardas
          <input
            type="text"
            name="name"
            id="name"
            className="form__input"
            ref={register({ required: "Įveskite savo vardą" })}
          />
          {errors.name && (
            <div className="form__msg-error">{errors.name.message}</div>
          )}
        </label>
        <label htmlFor="email" className="form__label">
          El. paštas
          <input
            type="email"
            name="email"
            id="email"
            className="form__input"
            ref={register({ required: "Įveskite savo el. paštą" })}
          />
          {errors.email && (
            <div className="form__msg-error">{errors.email.message}</div>
          )}
        </label>
      </div>
      <label htmlFor="tel" className="form__label">
        Telefonas
        <input
          type="tel"
          name="tel"
          id="tel"
          className="form__input"
          ref={register({ required: "Įveskite savo telefono numerį" })}
        />
        {errors.tel && (
          <div className="form__msg-error">{errors.tel.message}</div>
        )}
      </label>
      <label htmlFor="date" className="form__label">
        Pageidaujama data
        <input
          type="input"
          name="date"
          id="date"
          className="form__input"
          ref={register({ required: "Įveskite pageidaujamą datą" })}
        />
        {errors.date && (
          <div className="form__msg-error">{errors.date.message}</div>
        )}
      </label>
      <label htmlFor="guests" className="form__label">
        Svečių skaičius
        <input
          type="input"
          name="guests"
          id="guests"
          className="form__input"
          ref={register({ required: "Įveskite numatomą svečių skaičių" })}
        />
        {errors.guests && (
          <div className="form__msg-error">{errors.guests.message}</div>
        )}
      </label>
      <label htmlFor="message" className="form__label">
        Komentarai
        <textarea
          type="textarea"
          placeholder="Klausimai? Specialūs pageidavimai?"
          name="message"
          id="message"
          className="form__textarea"
          ref={register({ required: "Nepamirškite palikti žinutės mums :)" })}
        />
        {errors.message && (
          <div className="form__msg-error">{errors.message.message}</div>
        )}
      </label>
      <button type="submit" className="form__button">
        Siųsti
      </button>
    </form>
  );

  return (
    <>
      {errors && errors.submit && showSubmitError(errors.submit.message)}
      {submitted ? showThankYou : showForm}
    </>
  );
};

export default Form;
