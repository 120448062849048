import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const MapContainer = () => {
  const mapStyles = {
    height: "300px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 55.004194,
    lng: 23.792972,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC2uFsOZH6tciP3uNZ93JNQMWcvX_gDvoM">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={15}
        center={defaultCenter}
      />
    </LoadScript>
  );
};

export default MapContainer;
